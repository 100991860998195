
export default [
  {
    id: "ninjakitchen-brand",
    title: "caseStudiesCardNineTitle",
    value: "59% higher basket completion",
    description: "caseStudiesCardNinetDescription",
    url: "/success-stories/ninjakitchen",
  },
  {
    id: "secretsales-brand",
    title: "caseStudiesCardEightTitle",
    value: "+11%+ lift in site revenue",
    description: "caseStudiesCardEightDescription",
    url: "/success-stories/secret-sales",
  },
  {
    id: "p2go-brand",
    title: "caseStudiesCardSevenTitle",
    value: "4.7%+ revenue per user",
    description: "caseStudiesCardSevenDescription",
    url: "/success-stories/parcel2go",
  },
  {
    id: "online4baby-brand",
    title: "caseStudiesCardSixTitle",
    value: "17.2% higher AOV",
    description: "caseStudiesCardSixDescription",
    url: "/success-stories/online4baby-perks",
  },
  {
    id: "plusnet-brand",
    title: "caseStudiesCardFiveTitle",
    value: "5% reduction in churn",
    description: "caseStudiesCardFiveDescription",
    url: "/success-stories/plusnet-perks",
  },
  {
    id: "wild-brand",
    title: "caseStudiesCardFourTitle",
    value: "£1m+ extra revenue",
    description: "caseStudiesCardFourDescription",
    url: "/success-stories/wild-natural-deodorant-case",
  },
]